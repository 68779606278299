import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
function KnowledgeTab({ id }) {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [loadingtrained, setLoadingtrained] = useState(false);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const trainingfile = async (file, botid) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("bot_id", botid);
    try {
      setLoadingtrained(true);
      const response = await fetch(
        "https://milio-monorepo-production.up.railway.app/training/file",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const data = await response.json();
      if (response.ok) {
        navigate("/ai-agent");
      }
    } catch (error) {
      console.error("Error creating file:", error);
    }
    setLoadingtrained(false);
  };
  return (
    <div>
      <div className="border-2 border-dashed border-gray-300 bg-white shadow-md w-full max-w-lg h-auto flex flex-col items-center justify-center rounded-lg p-4">
        <h3 className="text-lg font-bold mb-4">Trained File</h3>
        <input
          type="file"
          className="border w-full px-4 py-2 rounded-lg mb-4"
          placeholder="Choose File"
          onChange={handleFileChange}
        />
        <button
          onClick={() => trainingfile(file, id)}
          className="bg-yellow-300 px-6 py-2 rounded-md hover:bg-yellow-400"
        >
          {" "}
          {loadingtrained ? (
            <svg
              className="animate-spin h-5 w-5 text-slate-950"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Training File"
          )}
        </button>
      </div>
    </div>
  );
}

export default KnowledgeTab;
