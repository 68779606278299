import { Link, useNavigate } from 'react-router-dom';
function Footer() {
    return (
        <footer className="bg-yellow-400 text-whitE py-10">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between">
            {/* Logo & About Section */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h2 className="text-2xl font-bold text-white mb-4">Milio AI</h2>
              <p>
                Milio AI is a modern platform for amazing solutions. We bring
                the best for your business, community, or personal needs.
              </p>
              <div className="mt-4 flex space-x-3">
                <a
                  href="#"
                  className="text-white-400 hover:text-white transition duration-300"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="#"
                  className="text-gray-400 hover:text-white transition duration-300"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="#"
                  className="text-gray-400 hover:text-white transition duration-300"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="#"
                  className="text-gray-400 hover:text-white transition duration-300"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
  
            {/* Quick Links Section */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-lg font-semibold text-white mb-4">Quick Links</h3>
              <ul className="space-y-2">
    
                <li>
                  <Link  to="/billings"  className="hover:text-white transition duration-300"> Services</Link>
                </li>
                <li>
                  <a
                    href="mailto:miliochatbotai@gmail.com"
                    className="hover:text-white transition duration-300"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                <Link  to="/privacy-policy"  className="hover:text-white transition duration-300"> Privacy Policy</Link>
                </li>
              </ul>
            </div>
  
            {/* Newsletter Section */}
            <div className="w-full md:w-1/3">
              <h3 className="text-lg font-semibold text-white mb-4">
                Subscribe to Our Newsletter
              </h3>
              <p className="mb-4">
                Stay updated with our latest news and offers.
              </p>
              <form>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full p-2 rounded-md bg-white-800 text-gray-300 focus:ring-2 focus:ring-green-400"
                />
                <button
                  type="submit"
                  className="mt-3 w-full bg-yellow-600 hover:bg-yellow-600 text-white font-semibold py-2 rounded-md transition duration-300"
                >
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </footer>
    );
  }

  export default Footer;