import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import GeneralTab from "./GeneralTab";
import KnowledgeTab from "./KnowledgeTab";

function BotSettings() {
  const { id } = useParams();
  const location = useLocation();
  const botData = location.state;
  const [activeTab, setActiveTab] = useState("general");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className="p-6 min-h-screen">
      <h1 className="text-xl sm:text-2xl font-bold mb-4 text-center">
        Bot Settings
      </h1>
      <div className="flex mb-6 border-b">
        <button
          className={`px-4 py-2 ${
            activeTab === "general" ? "border-b-2 border-yellow-500" : ""
          }`}
          onClick={() => handleTabClick("general")}
        >
          General
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === "knowledge" ? "border-b-2 border-yellow-500" : ""
          }`}
          onClick={() => handleTabClick("knowledge")}
        >
          Knowledge Sources
        </button>
      </div>
      {activeTab === "general" && <GeneralTab data={botData[0]} />}
      {activeTab === "knowledge" && <KnowledgeTab id={id} />}
    </div>
  );
}

export default BotSettings;
