import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ReactSVG } from "react-svg";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "../components/ProtectedRoute";
import circularLoading from "../assets/circular-loading.svg";
import Arrowback from "../assets/arrow-back.svg";
import HamburgerIcon from "../assets/hamburger-icon.svg";
const Layout = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    navigate("/login");
  };
  return (
    <div>
      <ToastContainer></ToastContainer>
      <div className="flex h-screen">
        <div
          className="w-16 hover:w-64 bg-yellow-500 text-white h-full transition-all duration-300 overflow-hidden group"
          onMouseEnter={(e) => e.currentTarget.classList.add("w-64")}
          onMouseLeave={(e) => e.currentTarget.classList.remove("w-64")}
        >
          <div className="flex flex-col items-center p-4">
            <div className="flex flex-col gap-4">
              <Link
                to="/dashboard"
                className="flex items-center gap-4 p-2 rounded-md hover:bg-gray-700 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 3.75h6.75v6.75H3.75V3.75zM13.5 3.75h6.75v6.75H13.5V3.75zM3.75 13.5h6.75v6.75H3.75v-6.75zM13.5 13.5h6.75v6.75H13.5v-6.75z"
                  />
                </svg>

                <span className="group-hover:block hidden">Dashboard</span>
              </Link>
              <Link
                to="/myaccount"
                className="flex items-center gap-4 p-2 rounded-md hover:bg-gray-700 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M12 12c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm0 2c-3.33 0-10 1.67-10 5v3h20v-3c0-3.33-6.67-5-10-5z" />
                </svg>

                <span className="group-hover:block hidden">My Account</span>
              </Link>
              <Link
                to="/chatbot"
                className="flex items-center gap-4 p-2 rounded-md hover:bg-gray-700 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 2.25C7.25 2.25 3.75 5.75 3.75 9.75s2.63 6.75 6.75 6.75c.75 0 1.5-.25 2.25-.5l4.25 2-.5-4.5c.75-1 1.25-2.25 1.25-3.75 0-4-3.5-7.5-7.5-7.5zM8.75 10.5h.01M15.25 10.5h.01M10.25 13.25c1 1 2.5 1 3.5 0"
                  />
                </svg>

                <span className="group-hover:block hidden">Chatbot</span>
              </Link>
              <Link
                to="/connected-platform"
                className="flex items-center gap-4 p-2 rounded-md hover:bg-gray-700 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 2.25C7.25 2.25 3.75 5.75 3.75 9.75s2.63 6.75 6.75 6.75c.75 0 1.5-.25 2.25-.5l4.25 2-.5-4.5c.75-1 1.25-2.25 1.25-3.75 0-4-3.5-7.5-7.5-7.5zM8.75 10.5h.01M15.25 10.5h.01M10.25 13.25c1 1 2.5 1 3.5 0"
                  />
                </svg>

                <span className="group-hover:block hidden">
                  Connected Platform
                </span>
              </Link>
              <Link
                to="/inbox"
                className="flex items-center gap-4 p-2 rounded-md hover:bg-gray-700 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 12.75v7.5c0 .966-.784 1.75-1.75 1.75H4c-.966 0-1.75-.784-1.75-1.75v-7.5m19.5-6.748V6c0-.966-.784-1.75-1.75-1.75H4c-.966 0-1.75.784-1.75 1.75v.002M12 12.75v3.75m0 0h6.75m-6.75 0H5.25"
                  />
                </svg>

                <span className="group-hover:block hidden">Inbox</span>
              </Link>
              <Link
                to="/contacts"
                className="flex items-center gap-4 p-2 rounded-md hover:bg-gray-700 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M21 8V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v1l9 5 9-5zm-9 7L3 10v7a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7l-9 5z" />
                </svg>

                <span className="group-hover:block hidden">Contacts</span>
              </Link>
              <Link
                to="/broadcast"
                className="flex items-center gap-4 p-2 rounded-md hover:bg-gray-700 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <circle cx="32" cy="32" r="6" fill="currentColor" />

                  <path
                    d="M24 32c0-4.418 3.582-8 8-8s8 3.582 8 8-3.582 8-8 8-8-3.582-8-8z"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="2"
                  />

                  <path
                    d="M16 32c0-8.837 7.163-16 16-16s16 7.163 16 16-7.163 16-16 16-16-7.163-16-16z"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                  />

                  <path
                    d="M8 32c0-13.255 10.745-24 24-24s24 10.745 24 24-10.745 24-24 24S8 45.255 8 32z"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                  />
                </svg>

                <span className="group-hover:block hidden">Broadcast</span>
              </Link>
              <Link
                to="/human-agent"
                className="flex items-center gap-4 p-2 rounded-md hover:bg-gray-700 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  width="24"
                  height="24"
                  fill="#4A90E2"
                >
                  <circle cx="32" cy="18" r="10" fill="currentColor" />

                  <path
                    d="M16 54v-7c0-4.418 3.582-8 8-8h16c4.418 0 8 3.582 8 8v7H16z"
                    fill="currentColor"
                  />

                  <path
                    d="M12 20c0 6.627 5.373 12 12 12v-4c-4.418 0-8-3.582-8-8s3.582-8 8-8v-4c-6.627 0-12 5.373-12 12zm40 0c0 6.627-5.373 12-12 12v-4c4.418 0 8-3.582 8-8s-3.582-8-8-8v-4c6.627 0 12 5.373 12 12z"
                    fill="currentColor"
                  />
                </svg>

                <span className="group-hover:block hidden">Human Agent</span>
              </Link>
              <Link
                to="/ai-agent"
                className="flex items-center gap-4 p-2 rounded-md hover:bg-gray-700 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  width="24"
                  height="24"
                  fill="#4A90E2"
                >
                  <circle cx="32" cy="18" r="10" fill="currentColor" />

                  <path
                    d="M16 54v-7c0-4.418 3.582-8 8-8h16c4.418 0 8 3.582 8 8v7H16z"
                    fill="currentColor"
                  />

                  <path
                    d="M12 20c0 6.627 5.373 12 12 12v-4c-4.418 0-8-3.582-8-8s3.582-8 8-8v-4c-6.627 0-12 5.373-12 12zm40 0c0 6.627-5.373 12-12 12v-4c4.418 0 8-3.582 8-8s-3.582-8-8-8v-4c6.627 0 12 5.373 12 12z"
                    fill="currentColor"
                  />
                </svg>

                <span className="group-hover:block hidden">Ai Agent</span>
              </Link>
              {/* <Link
                to="/settings"
                className="flex items-center gap-4 p-2 rounded-md hover:bg-gray-700 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 2.25c.25 0 .5 0 .75.03v1.455c0 .8.73 1.43 1.5 1.43h.25c.77 0 1.5-.63 1.5-1.43V2.28c.63.12 1.25.33 1.83.61.22.1.3.36.16.57L14.7 5.04c-.4.63-.12 1.43.6 1.61l1.3.32c.49.12 1 .02 1.43-.3.2-.15.47-.05.53.2l.41 1.8c.11.47.04.97-.2 1.41-.13.24-.11.55.07.76l1.12 1.14c.25.26.23.65-.03.9-.44.44-.95.8-1.5 1.1-.26.14-.57.12-.77-.06l-1.14-1.12c-.2-.18-.52-.21-.76-.07-.45.24-.96.36-1.46.36-.38 0-.75-.08-1.1-.23l-1.32-.33c-.72-.18-1.62.2-2 .9L6.04 18.2c-.2.3-.56.37-.84.18-.6-.39-1.18-.87-1.72-1.42-.27-.26-.2-.7.13-.9l2.2-1.47c.71-.47 1.1-1.27.93-2l-.33-1.3c-.14-.56-.73-.88-1.29-.74l-1.8.41c-.24.05-.46-.03-.62-.22-.5-.57-.9-1.2-1.2-1.9-.09-.23-.06-.49.1-.67l1.46-2.2c.48-.71.29-1.63-.4-2.13L2.32 6.9c-.2-.13-.3-.37-.26-.6C2.46 4.64 3.48 3.47 4.8 2.69c.23-.14.52-.07.67.1L6.72 3.94c.4.4.9.65 1.46.66.65.01 1.19-.44 1.33-1.07l.33-1.3c.12-.46.55-.79 1.03-.79h.16zm2.25 9.25a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                  />
                </svg>

                <span className="group-hover:block hidden">Settings</span>
              </Link> */}
              <Link
                to="/billings"
                className="flex items-center gap-4 p-2 rounded-md hover:bg-gray-700 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M7.5 6.75h9m-9 4.5h9m-9 4.5h6M3.75 3.75v16.5c0 .621.504 1.125 1.125 1.125h14.25c.621 0 1.125-.504 1.125-1.125V3.75M9.75 3.75v16.5M14.25 3.75v16.5"
                  />
                </svg>

                <span className="group-hover:block hidden">Billings</span>
              </Link>

              <button
                onClick={handleLogout}
                className="flex items-center gap-4 w-full p-2 rounded-md hover:bg-red-600 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 16l4-4m0 0l-4-4m4 4H7"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 20H5a2 2 0 01-2-2V6a2 2 0 012-2h4"
                  />
                </svg>
                <span className="group-hover:block hidden">Logout</span>
              </button>
            </div>
          </div>
        </div>

        <div className="flex-1 bg-gray-100 p-6">
          <main>
            <ProtectedRoute />
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
