const PlanCard = ({ duration, price, plan_id }) => {
  const token = localStorage.getItem("authToken");
  const handlePayment = async (plan_id, duration) => {
    try {
      const response = await fetch(
        "https://milio-monorepo-production.up.railway.app/subscription/create-with-payment",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            plan_id: plan_id,
            duration: duration,
          }),
        }
      );
      const data = await response.json();
      window.open(
        data.redirect_url,
        "Payment",
        "width=600,height=700,left=100,top=100,resizable=no,scrollbars=no"
      );
    } catch (error) {
      console.error("Error Handle Payment:", error);
    }
  };
  return (
    <div class="border-yellow-400 border-gray-300 rounded-lg shadow-lg p-6 text-center">
      <p class="text-3xl font-bold text-gray-900 mb-2">
        <span className="font-bold">
          {price.toLocaleString("en-US", {
            style: "currency",
            currency: "IDR",
          })}
        </span>
      </p>
      <p class="text-sm text-gray-500 mb-4">{duration} Package</p>
      {/* <ul class="space-y-2 text-left text-sm text-gray-600 mb-6">
                <li>✔ 1,000 Monthly Active Users</li>
                <li>✔ 2 Human Agents</li>
                <li>✔ Unlimited AI Agents</li>
                <li>✔ Unlimited Connected Platforms</li>
                <li>✔ 5,000 AI Responses</li>
                <li>✔ Cekat.AI Advanced AI Models</li>
            </ul> */}
      <button
        onClick={() => handlePayment(plan_id, duration)}
        class="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
      >
        Buy Package
      </button>
    </div>
  );
};

export default PlanCard;
