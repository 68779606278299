import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
function GeneralTab({ data }) {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: data.name || "",
    description: data.description || "",
    tone: data.tone || "",
    goals_behavior: data.goals_behavior || "",
    human_handover: data.human_handover || {
      condition: "",
      keywords: [],
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleHandoverChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      human_handover: {
        ...prevData.human_handover,
        [name]: name === "keywords" ? value.split(",") : value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await fetch(
        `https://milio-monorepo-production.up.railway.app/bots/${data.id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      navigate("/ai-agent");
    } catch (error) {
      console.error("Failed to update bot:", error);
      alert("Failed to update bot!");
    }
    setLoading(false);
  };
  if (loading) {
    return <ClipLoader size={50} color={"#f1c40f"} />;
  }
  return (
    <div>
      <h1 className="text-xl sm:text-2xl font-bold mb-2 text-center">
        {data.name}
      </h1>
      <p className="text-gray-600 text-center">{data.goals_behavior} </p>
      <p className="text-gray-600 text-center">
        Last Trained At {data.trained_at}
      </p>
      <form onSubmit={handleSubmit}>
        {/* Name */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full px-4 py-2 mt-2 border rounded-lg bg-gray-100 text-gray-600"
          />
        </div>

        {/* Description */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Description
          </label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            className="w-full px-4 py-2 mt-2 border rounded-lg bg-gray-100 text-gray-600"
          />
        </div>

        {/* Tone */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Tone
          </label>
          <input
            type="text"
            name="tone"
            value={formData.tone}
            onChange={handleInputChange}
            className="w-full px-4 py-2 mt-2 border rounded-lg bg-gray-100 text-gray-600"
          />
        </div>

        {/* Goals Behavior */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Goals Behavior
          </label>
          <input
            type="text"
            name="goals_behavior"
            value={formData.goals_behavior}
            onChange={handleInputChange}
            className="w-full px-4 py-2 mt-2 border rounded-lg bg-gray-100 text-gray-600"
          />
        </div>

        {/* Human Handover */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Human Handover Condition
          </label>
          <input
            type="text"
            name="condition"
            value={formData.human_handover.condition}
            onChange={handleHandoverChange}
            className="w-full px-4 py-2 mt-2 border rounded-lg bg-gray-100 text-gray-600"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Keywords (comma-separated)
          </label>
          <input
            type="text"
            name="keywords"
            value={formData.human_handover.keywords.join(",")}
            onChange={handleHandoverChange}
            className="w-full px-4 py-2 mt-2 border rounded-lg bg-gray-100 text-gray-600"
          />
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="w-full bg-yellow-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 flex justify-center items-center"
          >
            Update Bot
          </button>
        </div>
      </form>
    </div>
  );
}

export default GeneralTab;
