import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
function Chatbot() {
  const navigate = useNavigate();
  const initialMetaAuthorization = () => {
    const metaAppId = "1157569126072713";
    const redirectUrl =
      "https://milio-monorepo-production.up.railway.app/auth/meta/callback";

    const url = `https://www.facebook.com/v21.0/dialog/oauth?client_id=${metaAppId}&redirect_uri=${encodeURIComponent(
      redirectUrl
    )}&scope=business_management,whatsapp_business_management,public_profile,email&response_type=code`;

    window.open(
      url,
      "MetaLogin",
      "width=600,height=700,left=100,top=100,resizable=no,scrollbars=no"
    );
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <h1 className="text-2xl font-semibold mb-8">Welcome Back to Milio AI</h1>

      <div className="space-y-4">
        <div
          className="flex items-center bg-white shadow-md rounded-lg p-4 w-80 cursor-pointer hover:bg-gray-100"
          onClick={() => initialMetaAuthorization()}
        >
          <img
            src="/images/whatsapp.png"
            alt="Connect Platform Icon"
            className="w-12 h-12 mr-4"
          />
          <div>
            <h2 className="text-lg font-bold">1. Connect to Whatsapp</h2>
            <p className="text-sm text-gray-500">
              Start receiving messages from your WhatsApp!
            </p>
          </div>
        </div>

        <div
          className="flex items-center bg-white shadow-md rounded-lg p-4 w-80 cursor-pointer hover:bg-gray-100"
          onClick={() => navigate("/ai-agent")}
        >
          <img
            src="/images/robot.png"
            alt="Connect Platform Icon"
            className="w-12 h-12 mr-4"
          />
          <div>
            <h2 className="text-lg font-bold">2. Create an Ai Agent</h2>
            <p className="text-sm text-gray-500">
              Answer incoming messages with custom AI Agent!
            </p>
          </div>
        </div>

        <div
          className="flex items-center bg-white shadow-md rounded-lg p-4 w-80 cursor-pointer hover:bg-gray-100"
          onClick={() => navigate("/human-agent")}
        >
          <img
            src="/images/human.png"
            alt="Connect Platform Icon"
            className="w-12 h-12 mr-4"
          />
          <div>
            <h2 className="text-lg font-bold">3. Invite Human Agent</h2>
            <p className="text-sm text-gray-500">
              Invite your team to help respond to chats!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chatbot;
