import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import PlatformDropdown from "../../components/PlatformDropdown";
import ReceipentsDropdown from "../../components/ReceipentDropdown";
import TemplateDropdown from "../../components/TemplateDropdown";
const Campaign = () => {
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedreceipent, setSelectedReceipent] = useState("");
  const [selectedtemplate, setSelectedTemplate] = useState("");
  const [campaign, setCampaign] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCampaign, setNewCampaign] = useState({
    name: "",
    schedule: "",
  });
  const handlePlatfromChange = (paltformid) => {
    setSelectedPlatform(paltformid);
  };
  const handleReceipentChange = (paltformid) => {
    setSelectedReceipent(paltformid);
  };
  const handleTemplateChange = (paltformid) => {
    setSelectedTemplate(paltformid);
  };
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("authToken");
  const rowsPerPage = 10;
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const fetchCampaign = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://milio-monorepo-production.up.railway.app/broadcast/campaigns",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCampaign(data.data);
      } else {
        alert("Failed to fetch campaign");
      }
    } catch (error) {
      console.error("Error fetching campaign:", error);
      alert("Error fetching campaign");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCampaign();
  }, []);

  const handleAddTemplate = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewCampaign({
      name: "",
      schedule: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const { schedule, schedulevalue } = e.target;
    setNewCampaign((prev) => ({
      ...prev,
      [name]: value,
      [schedule]: schedulevalue,
    }));
  };

  const handleSaveCampaign = async () => {
    if (
      newCampaign.name &&
      newCampaign.schedule &&
      selectedPlatform &&
      selectedreceipent &&
      selectedtemplate
    ) {
      setLoading(true);
      try {
        const response = await fetch(
          "https://milio-monorepo-production.up.railway.app/broadcast/campaigns",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: newCampaign.name,
              sender_id: selectedPlatform,
              recipient_id: selectedreceipent,
              template_id: selectedtemplate,
              schedule: newCampaign.schedule,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          fetchCampaign();
          alert("Campaign added successfully!");
          handleCloseModal();
        } else {
          const errorData = await response.json();
          alert(`Error: ${errorData.message || "Failed to add campaign"}`);
        }
      } catch (error) {
        alert(`Error: ${error.message || "Network error"}`);
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please fill in all fields");
    }
  };

  const paginatedTemplates = campaign.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  return (
    <div>
      <h2 className="text-lg font-bold mb-4">Campaign</h2>
      {loading && <ClipLoader size={50} color={"#f1c40f"} />}
      <button
        onClick={handleAddTemplate}
        className="px-4 py-2 bg-yellow-500 hover:bg-blue-600 text-white rounded flex  mb-4"
      >
        Add Campaign
      </button>

      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-200 text-sm sm:text-base">
          <thead className="bg-gray-100">
            <tr>
              <th className="border border-gray-200 px-2 py-2 text-left">
                Campaign Name
              </th>
              <th className="border border-gray-200 px-2 py-2 text-left">
                Recipent List
              </th>
              <th className="border border-gray-200 px-2 py-2 text-left">
                Delivered
              </th>
              <th className="border border-gray-200 px-2 py-2 text-left">
                Status
              </th>
              <th className="border border-gray-200 px-2 py-2 text-left">
                Sender
              </th>
              <th className="border border-gray-200 px-2 py-2 text-left">
                Created At
              </th>
              <th className="border border-gray-200 px-2 py-2 text-left">
                Send At
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedTemplates.length > 0 ? (
              paginatedTemplates.map((campaign) => (
                <tr key={campaign.id} className="text-center">
                  <td className="border border-gray-200 px-2 py-2">
                    {campaign.name || "N/A"}
                  </td>
                  <td className="border border-gray-200 px-2 py-2">
                    {campaign.recipient.name || "N/A"}
                  </td>
                  <td className="border border-gray-200 px-2 py-2">
                    {formatDate(campaign.schedule) || "N/A"}
                  </td>
                  <td className="border border-gray-200 px-2 py-2">
                    {campaign.status || "N/A"}
                  </td>
                  <td className="border border-gray-200 px-2 py-2">
                    {campaign.sender.platform_name || "N/A"}
                  </td>
                  <td className="border border-gray-200 px-2 py-2">
                    {formatDate(campaign.created_at) || "N/A"}
                  </td>
                  <td className="border border-gray-200 px-2 py-2">
                    {campaign.sender.platform_type || "N/A"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="6"
                  className="border border-gray-200 px-2 py-2 text-center text-gray-500"
                >
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
            <h2 className="text-xl font-bold mb-4">Add Campaign</h2>
            <div className="space-y-4">
              <input
                type="text"
                name="name"
                value={newCampaign.name}
                onChange={handleInputChange}
                placeholder="Campaign Name"
                className="w-full border px-4 py-2 rounded"
              />
              <PlatformDropdown onPlatfromChange={handlePlatfromChange} />
              <ReceipentsDropdown onReceipentChange={handleReceipentChange} />
              <TemplateDropdown onTemplateChange={handleTemplateChange} />
              <input
                type="datetime-local"
                name="schedule"
                value={newCampaign.schedule}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleCloseModal}
                className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveCampaign}
                className="px-4 py-2 bg-yellow-500 hover:bg-blue-600 text-white rounded"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Campaign;
