import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
const Template = () => {
  const [templates, setTemplates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTemplate, setNewTemplate] = useState({
    template_name: "",
    template_type: "",
    platform_name: "",
    language: "",
    category: "",
    body: "",
  });
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("authToken");
  const rowsPerPage = 10;

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://milio-monorepo-production.up.railway.app/broadcast/templates",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setTemplates(data.data);
      } else {
        alert("Failed to fetch templates");
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
      alert("Error fetching templates");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleAddTemplate = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewTemplate({
      template_name: "",
      template_type: "",
      platform_name: "",
      language: "",
      category: "",
      body: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTemplate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveTemplate = async () => {
    if (
      newTemplate.template_name &&
      newTemplate.template_type &&
      newTemplate.platform_name &&
      newTemplate.language &&
      newTemplate.body &&
      newTemplate.category
    ) {
      setLoading(true);
      try {
        const response = await fetch(
          "https://milio-monorepo-production.up.railway.app/broadcast/templates",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newTemplate),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setTemplates((prev) => [...prev, data]);
          fetchTemplates();
          alert("Template added successfully!");
          handleCloseModal();
        } else {
          const errorData = await response.json();
          alert(`Error: ${errorData.message || "Failed to add template"}`);
        }
      } catch (error) {
        alert(`Error: ${error.message || "Network error"}`);
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please fill in all fields");
    }
  };

  const paginatedTemplates = templates.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  return (
    <div>
      <h2 className="text-lg font-bold mb-4">Template Message</h2>
      {loading && <ClipLoader size={50} color={"#f1c40f"} />}
      <button
        onClick={handleAddTemplate}
        className="px-4 py-2 bg-yellow-500 hover:bg-blue-600 text-white rounded flex  mb-4"
      >
        Add Template
      </button>

      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-200 text-sm sm:text-base">
          <thead className="bg-gray-100">
            <tr>
              <th className="border border-gray-200 px-2 py-2 text-left">
                Template Name
              </th>
              <th className="border border-gray-200 px-2 py-2 text-left">
                Template Type
              </th>
              <th className="border border-gray-200 px-2 py-2 text-left">
                Platform Name
              </th>
              <th className="border border-gray-200 px-2 py-2 text-left">
                Language
              </th>
              <th className="border border-gray-200 px-2 py-2 text-left">
                Category
              </th>
              <th className="border border-gray-200 px-2 py-2 text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedTemplates.length > 0 ? (
              paginatedTemplates.map((template) => (
                <tr key={template.id} className="text-center">
                  <td className="border border-gray-200 px-2 py-2">
                    {template.template_name || "N/A"}
                  </td>
                  <td className="border border-gray-200 px-2 py-2">
                    {template.template_type || "N/A"}
                  </td>
                  <td className="border border-gray-200 px-2 py-2">
                    {template.platform_name || "N/A"}
                  </td>
                  <td className="border border-gray-200 px-2 py-2">
                    {template.language || "N/A"}
                  </td>
                  <td className="border border-gray-200 px-2 py-2">
                    {template.category || "N/A"}
                  </td>
                  <td className="border border-gray-200 px-2 py-2">
                    {template.status || "N/A"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="6"
                  className="border border-gray-200 px-2 py-2 text-center text-gray-500"
                >
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
            <h2 className="text-xl font-bold mb-4">Add Template</h2>
            <div className="space-y-4">
              <input
                type="text"
                name="template_name"
                value={newTemplate.template_name}
                onChange={handleInputChange}
                placeholder="Template Name"
                className="w-full border px-4 py-2 rounded"
              />
              <input
                type="text"
                name="template_type"
                value={newTemplate.template_type}
                onChange={handleInputChange}
                placeholder="Template Type"
                className="w-full border px-4 py-2 rounded"
              />
              <input
                type="text"
                name="platform_name"
                value={newTemplate.platform_name}
                onChange={handleInputChange}
                placeholder="Platform Name"
                className="w-full border px-4 py-2 rounded"
              />
              <input
                type="text"
                name="language"
                value={newTemplate.language}
                onChange={handleInputChange}
                placeholder="Language"
                className="w-full border px-4 py-2 rounded"
              />
              <input
                type="text"
                name="category"
                value={newTemplate.category}
                onChange={handleInputChange}
                placeholder="Category"
                className="w-full border px-4 py-2 rounded"
              />
              <input
                type="textarea"
                name="body"
                value={newTemplate.body}
                onChange={handleInputChange}
                placeholder="Body"
                className="w-full border px-4 py-2 rounded"
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleCloseModal}
                className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveTemplate}
                className="px-4 py-2 bg-yellow-500 hover:bg-blue-600 text-white rounded"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Template;
