import { createContext, useState, useEffect } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [userPlans, Setuserplans] = useState([]);
  const [bot, SetBot] = useState([]);
  const [plans, setPlans] = useState(null);
  const token = localStorage.getItem("authToken");
  const fetchUserPlan = async () => {
    try {
      const response = await fetch(
        "https://milio-monorepo-production.up.railway.app/subscription/my-subscriptions",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      Setuserplans(data);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const fetchBot = async () => {
    try {
      const response = await fetch(
        "https://milio-monorepo-production.up.railway.app/bots",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      SetBot(data);
    } catch (error) {
      console.error("Error fetching BOT:", error);
    }
  };

  const fetchDataPlan = async () => {
    try {
      const response = await fetch(
        "https://milio-monorepo-production.up.railway.app/plans",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setPlans(data);

      // setBusinessPlans(data.filter((plan) => plan.name === "Business"));
      // setEnterprisePlans(data.filter((plan) => plan.name === "Enterprise"));
      // setUnlimitedPlans(data.filter((plan) => plan.name === "Unlimited"));
      // setProPlans(data.filter((plan) => plan.name === "Pro"));
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  useEffect(() => {
    fetchBot();
    fetchDataPlan();
    fetchUserPlan();
  }, []);

  return (
    <AppContext.Provider value={{ userPlans, plans, bot }}>
      {children}
    </AppContext.Provider>
  );
};
