import React, { useEffect, useState } from "react";
import axios from "axios";

const ContactsDropdown = ({ onContactsChange }) => {
  const [contacts, setContacts] = useState([]); // Menyimpan daftar kontak
  const [selectedContact, setSelectedContact] = useState([]); // Menyimpan kontak yang dipilih
  const apiUrl = "https://milio-monorepo-production.up.railway.app/contacts";
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchContacts = async () => {
      if (!token) {
        console.error("Token tidak tersedia");
        return;
      }

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setContacts(response.data.data || []); // Pastikan `data` ada
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, [apiUrl, token]);

  const handleSelectContacts = (e) => {
    const selectedIds = Array.from(e.target.selectedOptions).map(
      (option) => option.value
    );
    setSelectedContact(selectedIds); // Perbarui state kontak yang dipilih
    onContactsChange(selectedIds); // Panggil callback dengan kontak yang dipilih
  };

  return (
    <div>
      <label htmlFor="contacts-dropdown" className="block mb-2 font-medium">
        Pilih Kontak:
      </label>
      <select
        id="contacts-dropdown"
        multiple
        value={selectedContact}
        onChange={handleSelectContacts}
        className="w-full border px-2 py-2 rounded"
      >
        {contacts.map((contact) => (
          <option key={contact.id} value={contact.id}>
            {contact.name} ({contact.phone})
          </option>
        ))}
      </select>
    </div>
  );
};

export default ContactsDropdown;
