import React, { useEffect, useState } from "react";
import axios from "axios";

const PlatformDropdown = ({ onPlatfromChange }) => {
  const [contacts, setContacts] = useState([]); // State untuk menyimpan kontak
  const [selectedContact, setSelectedContact] = useState(""); // State untuk kontak yang dipilih
  const apiUrl = "https://milio-monorepo-production.up.railway.app/platform";
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setContacts(response.data.data);
      } catch (error) {
        console.error("Error fetching platform:", error);
      }
    };

    fetchContacts();
  }, []);

  const handleSelectChange = (e) => {
    setSelectedContact(e.target.value);
    onPlatfromChange(e.target.value);
  };

  return (
    <div>
      <label htmlFor="contacts-dropdown">Pilih Platform:</label>
      <select
        id="contacts-dropdown"
        value={selectedContact}
        onChange={handleSelectChange}
      >
        <option value="">-- Pilih Platform --</option>
        {contacts.map((contact) => (
          <option key={contact.id} value={contact.id}>
            {contact.platform_name} ({contact.platform_type})
          </option>
        ))}
      </select>
      {selectedContact && (
        <p>
          Platform yang dipilih:{" "}
          {
            contacts.find((contact) => contact.id === selectedContact)
              ?.platform_type
          }
        </p>
      )}
    </div>
  );
};

export default PlatformDropdown;
