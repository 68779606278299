import React, { useEffect, useState } from "react";
import axios from "axios";

const TemplateDropdown = ({ onTemplateChange }) => {
  const [template, setTemplate] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const apiUrl =
    "https://milio-monorepo-production.up.railway.app/broadcast/templates";
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTemplate(response.data.data);
      } catch (error) {
        console.error("Error fetching template:", error);
      }
    };

    fetchTemplates();
  }, []);

  const handleSelectChange = (e) => {
    setSelectedTemplate(e.target.value);
    onTemplateChange(e.target.value);
  };

  return (
    <div>
      <label htmlFor="contacts-dropdown">Pilih Template:</label>
      <select
        id="contacts-dropdown"
        value={selectedTemplate}
        onChange={handleSelectChange}
      >
        <option value="">-- Pilih Template --</option>
        {template.map((contact) => (
          <option key={contact.id} value={contact.id}>
            {contact.template_name}
          </option>
        ))}
      </select>
      {selectedTemplate && (
        <p>
          template dipilih:{" "}
          {
            template.find((contact) => contact.id === selectedTemplate)
              ?.template_name
          }
        </p>
      )}
    </div>
  );
};

export default TemplateDropdown;
