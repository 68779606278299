import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
function AiAgent() {
  const [bots, setBots] = useState([]);
  const [selectedBot, setSelectedBot] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [botName, setBotName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchBot = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://milio-monorepo-production.up.railway.app/bots",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (data && data.data && data.data.length > 0) {
        setBots(data.data);
      } else {
        setBots([]);
      }
    } catch (error) {
      console.error("Error fetching Bots:", error);
      alert("Error", error);
      setBots([]);
    }
    setLoading(false);
  };
  const handleAddTemplate = () => {
    setIsModalOpen(true);
  };
  const createBot = async (botname, description) => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://milio-monorepo-production.up.railway.app/bots",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: botname,
            description: description,
          }),
        }
      );

      const data = await response.json();
      if (data.success == true) {
        fetchBot();
      }
    } catch (error) {
      alert("Error", error);
      console.error("Error creating Bots:", error);
    }
    setLoading(false);
  };
  const handleBotSettings = (id) => {
    const databot = bots.filter((bot) => bot.id === id);
    navigate(`/bot/settings/${id}`, { state: databot });
  };
  const deleteBot = async (id) => {
    try {
      setLoading(true);

      const response = await fetch(
        `https://milio-monorepo-production.up.railway.app/bots/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseBody = await response.text();
      if (response.ok) {
        fetchBot();
      }
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${responseBody}`);
      }
    } catch (error) {
      console.error("Error deleting bot:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBot();
  }, []);
  if (loading) {
    return <ClipLoader size={50} color={"#f1c40f"} />;
  }
  return (
    <div className="flex flex-col text-gray-800">
      {/* Main Content */}
      <main className="flex-1 flex flex-col lg:flex-row">
        {/* Left Side */}
        <div className="flex-1 flex flex-col items-center justify-center p-4 md:p-6">
          <h1 className="text-3xl font-bold text-center mb-2">AI Agents</h1>
          <p className="text-center text-gray-600 mb-6">
            Ini adalah halaman di mana Anda dapat mengunjungi AI yang telah Anda
            buat sebelumnya. <br />
            Jangan ragu untuk membuat perubahan dan membuat chatbot sebanyak
            yang Anda inginkan kapan saja!
          </p>
          {/* Create Chatbot Card */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-center">
            {/* Tampilkan Platform Cards */}
            {bots.length > 0 ? (
              bots.map((bot) => (
                <div
                  key={bot.id}
                  className="w-full h-55 w-55 bg-white shadow-md rounded-lg flex flex-col items-center justify-center border border-gray-200 cursor-pointer hover:shadow-lg"
                >
                  <div className="text-4xl">📱</div> {/* Ikon placeholder */}
                  <h3 className="text-lg font-bold mt-2 text-center">
                    {bot.name}
                  </h3>
                  <p className="text-sm text-gray-500 ml-2 mr-2 text-center">
                    {bot.description}
                  </p>
                  <div className="flex gap-2 mt-4 w-full px-2 justify-center items-center">
                    <button
                      onClick={() => handleBotSettings(bot.id)}
                      className="flex justify-center items-center gap-2 px-4 py-2 w-20 h-8 border border-gray-400 rounded-md hover:bg-gray-100 transition"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 text-gray-700"
                      >
                        <path d="M12 2a10 10 0 110 20 10 10 0 010-20zm0 18a8 8 0 100-16 8 8 0 000 16zm.5-13h-1v5h4v-1h-3V7z" />
                      </svg>
                      <span className="text-gray-700 font-medium">
                        Settings
                      </span>
                    </button>
                    <button
                      onClick={() => deleteBot(bot.id)}
                      className="flex justify-center items-center gap-2 px-4 py-2 w-20 h-8 border border-red-500 text-red-500 rounded-md hover:bg-red-50 transition"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path d="M6 19a2 2 0 002 2h8a2 2 0 002-2V7H6v12zM8 9h8v8H8V9zm5-6h-2l-1 1H7v2h10V4h-3l-1-1z" />
                      </svg>
                      <span className="font-medium">Delete</span>
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-500">
                No bots found. Create one to get started!
              </p>
            )}
            <div
              className="w-full h-40 bg-yellow-500 shadow-md rounded-lg flex flex-col items-center justify-center cursor-pointer hover:bg-blue-600"
              onClick={() => handleAddTemplate()}
            >
              <div className="text-4xl text-white">+</div>
              <h3 className="text-lg font-bold text-white mt-2">Create New</h3>
            </div>
          </div>
        </div>
      </main>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
            <h2 className="text-xl font-bold mb-4">Add Bot</h2>
            <div className="space-y-4">
              <input
                type="text"
                name="name"
                value={botName}
                onChange={setBotName}
                placeholder="Bot Name"
                className="w-full border px-4 py-2 rounded"
              />
              <input
                type="text"
                name="description"
                value={description}
                onChange={setDescription}
                placeholder="Description"
                className="w-full border px-4 py-2 rounded"
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleCloseModal}
                className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded mr-2"
              >
                Cancel
              </button>
              <button
                // onClick={createBot(botName, description)}
                className="px-4 py-2 bg-yellow-500 hover:bg-blue-600 text-white rounded"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AiAgent;
